@if(content) {
<div class="introduction intro-image">
  <img
      [ngSrc]="getOptimizedImageUrl(content?.image.data.attributes.url, 600)"
      [alt]="content?.image.data.attributes.alternativeText || 'Introduction Image'"
      class="intro-image__img"
      [srcset]="generateSrcSet(content?.image.data.attributes.url)"
      sizes="(max-width: 768px) 100vw, 50vw"
      [width]="content?.image.data.attributes.width"
      [height]="content?.image.data.attributes.height"
      priority
      decoding="async"
/>
  <div class="gap-8 inline-flex flex-col text-content" [class.align-items-center]="useCenter"
    [class.display-flex-when-center]="useCenter">
    <!-- <div class="gap-4 flex flex-col  content-padding"> -->
    <div class="flex flex-col  content-margin introduction-title-container content-padding">

      <h2 class="intro-title mb-0 font-univia-pro-70 text-align-left">
        {{content?.title}}
      </h2>
      <h2 class="intro-title mb-0 font-univia-pro-70 text-align-left">
        {{content?.title2}}
      </h2>
      @if(content?.description){
      <div class="subtitle-content mb-0 font-calibre-18 width-50 pt-4 pt-md-0" [class.margin-auto]="useCenter">
        {{content?.description}}
      </div>
      }
    </div>
  </div>
</div>
}