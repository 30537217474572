import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { NeoDialogData } from "../../models/neo-dialog.data";
import { AngularMaterialModule } from "../../modules/angularmaterial.module";

@Component({
    standalone: true,
    imports: [AngularMaterialModule],
    templateUrl: "./neo-dialog.component.html",
    styleUrls: ["./neo-dialog.component.scss"]
})
export class NeoDialogComponent {
    data1 = null

    constructor(public dialogRef: MatDialogRef<NeoDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: NeoDialogData) {
                  this.data1 = data;
                 }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
