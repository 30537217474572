import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom  } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withFetch,  } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DomLocationProvider } from './providers/dom-location.provider';
import { provideAnimations } from '@angular/platform-browser/animations';
 import { VorsorgerechnerModule } from 'vorsorgerechner-lib';
import { ApolloModule } from 'apollo-angular';
import { GraphQLModule } from './graphql/graphql.module';
import { CmsService } from './services/cms.service';


export function preloadAppData(cmsService: CmsService, translate: TranslateService){
  return (): Promise<void> => {
    return new Promise((resolve,reject) => {
      const lang = translate.currentLang || "de";
    const populationString = `?populate=deep&locale=${lang}`;

      cmsService.singlePageContent('home-page', populationString).subscribe({
        next: (res) => {
          cmsService.setHomeData(res)
          resolve();
        },
        error: (err) => {
          console.error(err)
          reject(err)
        }
      })
    })
  }
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    DomLocationProvider,
     importProvidersFrom(VorsorgerechnerModule),
    importProvidersFrom(ApolloModule),
    importProvidersFrom(GraphQLModule),
    provideAnimations(),
    CmsService,
    {
      provide: APP_INITIALIZER,
      useFactory: preloadAppData,
      deps: [CmsService, TranslateService],
      multi: true
    }
  ]
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

